import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import _ from "lodash";
import { IntlMessages } from "react-platform-components/dist/Translations";
import { injectToBaseRegisterContainer } from "react-platform-components/dist/BaseComponents/BaseInjectors";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(6),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(4),
  },
  buttonLink:{
    backgroundColor:"#545252"
  }
}));

function RegisterAs() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={12} className={classes.buttonContainer}>
        <Button
          component={Link}
          to={`/register-individual${history.location.search}`}
          variant="contained"
          color="secondary"
          size="large"
          className={classes.buttonLink}
          fullWidth
        >
          <IntlMessages id="Sign Up as individual" />
        </Button>
      </Grid>
      <Grid item xs={12} className={classes.buttonContainer}>
        <Button
          component={Link}
          to={`/register-enterprise${history.location.search}`}
          variant="contained"
          color="secondary"
          size="large"
          className={classes.buttonLink}
          fullWidth
        >
          <IntlMessages id="Sign Up as entreprise" />
        </Button>
      </Grid>
      <Grid item container justify="flex-end">
        <Grid item>
          <Link to="/login" variant="body2">
            <IntlMessages
              id="Already have an account? Sign in"
              defaultMessage="Already have an account? Sign in"
            />
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default injectToBaseRegisterContainer(
  _.flowRight(
    connect((state) => {
      return {
        inProgress: state.registerReducer.inProgress,
        roles: state.registerWithInlinesReducer.roles,
      };
    }),
    injectIntl
  ),
  { form: RegisterAs }
);
