import React from 'react'
import _ from 'lodash'
import { buildInjectToBaseRegisterContainer } from 'react-platform-user/dist/RegisterWithInlines/RegisterWithInlines'
import RegisterWithInlinesForm from 'react-platform-user/dist/RegisterWithInlines/RegisterWithInlinesForm'
import {
	CATEGORIE_STORE_ENTREPRISE_UUID,
	CATEGORIE_STORE_ASSOCIATION_UUID,
	ENTREPRISE_ROLE_ID,
	ASSOCIATION_ROLE_ID
} from "../../../configs"


export default buildInjectToBaseRegisterContainer({
	form: RegisterWithInlinesForm,
	setRoleValueToFormData: ({ roles, categories, values }) => {
		const category = _.find(categories, { id: values.category })
		if (category.uuid === CATEGORIE_STORE_ENTREPRISE_UUID) {
			return { 'user_roles-0-role': _.find(roles, { id: ENTREPRISE_ROLE_ID })?.id }
		} else if (category.uuid === CATEGORIE_STORE_ASSOCIATION_UUID) {
			return { 'user_roles-0-role': _.find(roles, { id: ASSOCIATION_ROLE_ID })?.id }
		} else {
			throw Error("Cannot find the fit category")
		}
	}
})
