import React from "react";
import { CreateProductSalable } from "react-platform-productsalable/dist/CreateProductSalable/forms/CreateProductSalable";
import {CustomCreateProductSalableForm} from "./CustomCreateProductSalableForm"

export function CreateProductSalableWrapper(props) {
 return <CreateProductSalable 
    createProductSalableForm={CustomCreateProductSalableForm}
    endpoint="products"
       /> 
}
