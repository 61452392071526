import _ from 'lodash';
import React from 'react';
import { all, call, takeEvery } from 'redux-saga/effects';
import { generateRoutesFromConfigs } from 'react-platform-components/dist/helpers';
import { IntlMessages } from 'react-platform-components/dist/Translations';
import { LocaleConfig } from 'react-platform-components/dist/Translations/TranslationsConfig';
import { LoginConfig } from 'react-platform-user/dist/Login/LoginConfig';
import { ProfileConfig } from 'react-platform-user/dist/Profile/ProfileConfig';
import { RegisterConfig } from 'react-platform-user/dist/Register/RegisterConfig';
import { ForgotPasswordConfig } from 'react-platform-user/dist/ForgotPassword/ForgotPasswordConfig';
import { DashboardConfig } from './containers/Dashboard/DashboardConfig';
import { CategoryOfProductConfig } from 'react-platform-category-of-product/dist/CategoryOfProduct/CategoryOfProductConfig';
import { ProductConfig } from 'react-platform-product/dist/Product/ProductConfig';
import { ProductDetailsConfig } from 'react-platform-product/dist/ProductDetails/ProductDetailsConfig';
import { PurchaseConfig } from 'react-platform-purchase/dist/Purchase/PurchaseConfig';
import { ShoppingCartConfig } from 'react-platform-shoppingcart/dist/ShoppingCart/ShoppingCartConfig';
import { TransactionsConfig } from 'react-platform-transactions/dist/Transactions/TransactionsConfig';
import { ProductSalableConfig } from 'react-platform-productsalable/dist/ProductSalable/ProductSalableConfig';
import { AddressOfProductConfig } from 'react-platform-address-of-product/dist/AddressOfProduct/AddressOfProductConfig';
import { OptionOfProductConfig } from 'react-platform-option-of-product/dist/OptionOfProduct/OptionOfProductConfig';
import { StoreConfig } from 'react-platform-store/dist/Store/StoreConfig';
import { StoreDetailsConfig } from 'react-platform-store/dist/Store/StoreDetailsConfig';
import { RegisterWithInlinesConfig } from 'react-platform-user/dist/RegisterWithInlines/RegisterWithInlinesConfig'
import { AddressConfig } from 'react-platform-address/dist/Address/AddressConfig';
import { SpecificitiesOfProductConfig } from 'react-platform-specificity-of-product/dist/SpecificityOfProduct/SpecificitiesOfProductConfig';
import { BookmarkOfStoreConfig } from 'react-platform-bookmark-of-store/dist/BookmarkOfStore/BookmarkOfStoreConfig'
import { BookmarkOfProductConfig } from "react-platform-bookmark-of-product/dist/BookmarkOfProduct/BookmarkOfProductConfig"
import { ChatConfig, ConversationConfig } from "react-platform-chat/dist/Chat/ChatConfig";
import {CustomRegisterConfig} from "./containers/CustomRegister/CustomRegisterConfig"
import {CreatedReviewOfProductConfig} from "react-platform-review-of-product/dist/ReviewOfProduct/Created/CreatedReviewOfProductConfig"
import {RecievedReviewOfProductConfig} from "react-platform-review-of-product/dist/ReviewOfProduct/Recieved/RecievedReviewOfProductConfig"
import { CreateProductSalableConfig } from "./containers/ProductSalable/CreateProductSalableConfig.js";
import {ActiveSubscriptionsConfig} from "react-platform-stripesubscriptions/dist/ActiveSubscriptions/ActiveSubscriptionsConfig"
import { StripeSubscriptionsConfig } from "react-platform-stripesubscriptions/dist/Subscriptions/SubscriptionsConfig";
import { SpecificitiesOfStoreConfig } from "react-platform-specificity-of-store/dist/SpecificityOfStore/SpecificitiesOfStoreConfig"
import {ProductFeaturingConfig} from "react-platform-productfeature/dist/ProductFeaturing/ProductFeaturingConfig"
import { CategoryOfStoreConfig } from 'react-platform-category-of-store/dist/CategoryOfStore/CategoryOfStoreConfig';

// todo: remove later (used just restart the deployment)
console.log('_')

const routeConfigs = [
    DashboardConfig,
    LoginConfig,
    ForgotPasswordConfig,
    ProfileConfig,
    ProductConfig,
    ProductDetailsConfig,
    StoreConfig,
    StoreDetailsConfig,
    TransactionsConfig,
    ShoppingCartConfig,
    BookmarkOfStoreConfig,
    BookmarkOfProductConfig,
    ChatConfig,
    ConversationConfig,
    CustomRegisterConfig,
    RecievedReviewOfProductConfig,
    CreatedReviewOfProductConfig,
    CreateProductSalableConfig ,
    StripeSubscriptionsConfig,
    ActiveSubscriptionsConfig,
];


export const navigationConfig = [
    {
        'auth': DashboardConfig.auth,
        'icon': 'dashboard',
        'id': 'dashboard-component',
        'title': <IntlMessages id="Dashboard" defaultMessage="Dashboard" />,
        'type': 'item',
        'url': '/dashboard'
    },
    
    {
        'auth': ProductConfig.auth,
        'icon': 'assignment_ind',
        'id': 'product-component',
        'title': <IntlMessages id="Mes Produits" defaultMessage="Mes Produits" />,
        'type': 'item',
        'url': '/product-salable'
    },
    {
        'auth': StoreConfig.auth,
        'icon': 'assignment_ind',
        'id': 'store-component',
        'title': <IntlMessages id="Store" defaultMessage="Store" />,
        'type': 'item',
        'url': '/store'
    },
    {
        'auth': TransactionsConfig.auth,
        'icon': 'assignment_ind',
        'id': 'transactions-component',
        'title': <IntlMessages id="Transactions" defaultMessage="Transactions" />,
        'type': 'item',
        'url': '/transactions'
    },
    {
        'auth': ShoppingCartConfig.auth,
        'icon': 'assignment_ind',
        'id': 'shopping-component',
        'title': <IntlMessages id="Mon panier" defaultMessage="Mon panier" />,
        'type': 'item',
        'url': '/cart'
    },
    {
        'auth': BookmarkOfStoreConfig.auth,
        'icon': 'favorite',
        'id': 'bookmarks-of-store',
        'title': <IntlMessages id="Stores bookmarks" defaultMessage="Stores bookmarks" />,
        'type': 'item',
        'url': '/store-bookmarks'
    },
    {
        auth: BookmarkOfProductConfig.auth,
        id: "product-bookmark-component",
        title: <IntlMessages id="Products bookmarks" defaultMessage="Products bookmarks" />,
        type: "item",
        url: "/product-bookmarks",
    },
    
];


export const routes = [
    ...generateRoutesFromConfigs(routeConfigs),
    {
        // eslint-disable-next-line react/display-name
        auth: ['user'],
        component: () => <div />,
        path: '/',
    }
];


function* redirect(action) {
    yield call(action.history.push, action.to)
}

function* sagas() {
    yield all([
        takeEvery('REDIRECT', redirect)
    ])
}


export function* rootSaga() {
    yield all([
        sagas(),
        LocaleConfig.actions(),
        LoginConfig.actions(),
        RegisterConfig.actions(),
        ForgotPasswordConfig.actions(),
        ProfileConfig.actions(),
        CategoryOfProductConfig.actions(),
        SpecificitiesOfProductConfig.actions(),
        ProductConfig.actions(),
        ProductDetailsConfig.actions(),
        ProductSalableConfig.actions(),
        AddressOfProductConfig.actions(),
        AddressConfig.actions(),
        OptionOfProductConfig.actions(),
        StoreConfig.actions(),
        RegisterWithInlinesConfig.actions(),
        TransactionsConfig.actions(),
        ShoppingCartConfig.actions(),
        PurchaseConfig.actions(),
        BookmarkOfStoreConfig.actions(),
        BookmarkOfProductConfig.actions(),
        ChatConfig.actions(),
        CreatedReviewOfProductConfig.actions(),
        ActiveSubscriptionsConfig.actions(),
        StripeSubscriptionsConfig.actions(),       
        SpecificitiesOfStoreConfig.actions(),
        ProductFeaturingConfig.actions(),
        CategoryOfStoreConfig.actions()
    ])
}



function errorMessage(state = { error: null }, action) {

    switch (action.type) {
        case "HANDLE_ERROR": {
            return { ...state, error: action.error }
        }
        case "CLEAR_ERROR": {
            return { ...state, error: null }
        }
        default: return { ...state }
    }
}

function successMessage(state = { message: null }, action) {

    switch (action.type) {
        case "HANDLE_SUCCESS_MESSAGE": {
            return { ...state, message: action.message }
        }
        case "CLEAR_SUCCESS_MESSAGE": {
            return { ...state, message: null }
        }
        default: return { ...state }
    }
}

function auth(state = { roles: ['guest'] }, action) {

    switch (action.type) {
        case 'USER_ALREAY_AUTHENTICATED': {

            const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
            return {
                user: {
                    ...user,
                    roles: user.roles.map(role => role.name)
                }
            }

        }
        case "FETCH_USER_START": {
            return { ...state }
        }
        case "FETCH_USER_ERROR": {
            return { ...state }
        }
        case "FETCH_USER_SUCCESS": {
            return {
                user: {
                    ...action.payload,
                    roles: action.payload.roles.map(role => role.name)
                }
            }
        }
        default: {
            const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
            return {
                user: {
                    ...user,
                    roles: user ? user.roles.map(role => role.name) : ['guest']
                }
            }
        }
    }
};

const defaultSettings = {
    layout: {
        config: {
            scroll : 'content',
            navbar : {
                display : true,
                folded  : false,
                position: 'left'
            },
            toolbar: {
                display : true,
                style   : 'fixed',
                position: 'below'
            },
            footer : {
                display : true,
                style   : 'fixed',
                position: 'below'
            },
        }
    }
}

const initialState = {
    defaults: _.merge({}, defaultSettings),
    current: _.merge({}, defaultSettings)
}

function settings(state = initialState, action) {
    switch (action.type) {
        case 'SET_SETTINGS': {
            return {
                ...state,
                current: _.merge({}, state.current, action.value)
            }
        }
        case 'SET_DEFAULT_SETTINGS': {
            return {
                ...state,
                defaults: _.merge({}, state.defaults, action.value),
                current: _.merge({}, state.defaults, action.value)
            }
        }
        case 'RESET_DEFAULT_SETTINGS': {
            return {
                ...state,
                defaults: _.merge({}, state.defaults),
                current: _.merge({}, state.defaults)
            }
        }
        default: {
            return state;
        }
    }
}


export const reducers = {
    auth,
    errorMessage,
    successMessage,
    settings,
    localeReducer: LocaleConfig.reducer,
    loginReducer: LoginConfig.reducer,
    registerReducer: RegisterConfig.reducer,
    forgotPasswordReducer: ForgotPasswordConfig.reducer,
    profileReducer: ProfileConfig.reducer,
    categoryOfProductReducer: CategoryOfProductConfig.reducer,
    specificityOfProductReducer: SpecificitiesOfProductConfig.reducer,
    ProductReducer: ProductConfig.reducer,
    ProductDetailsReducer: ProductDetailsConfig.reducer,
    productSalableReducer: ProductSalableConfig.reducer,
    addressOfProductReducer: AddressOfProductConfig.reducer,
    addressReducer: AddressConfig.reducer,
    optionOfProductReducer: OptionOfProductConfig.reducer,
    storeReducer: StoreConfig.reducer,
    registerWithInlinesReducer: RegisterWithInlinesConfig.reducer,
    transactionsReducer: TransactionsConfig.reducer,
    shoppingCartReducer: ShoppingCartConfig.reducer,
    purchaseReducer: PurchaseConfig.reducer,
    bookmarksReducer: BookmarkOfStoreConfig.reducer,
    bookmarkOfProductReducer: BookmarkOfProductConfig.reducer,
    chatReducer: ChatConfig.reducer,
    productReviewsReducer:CreatedReviewOfProductConfig.reducer ,
    activeSubscriptionsReducer:ActiveSubscriptionsConfig.reducer,
    stripeSubscriptionsReducer:StripeSubscriptionsConfig.reducer,  
    specificityOfStoreReducer: SpecificitiesOfStoreConfig.reducer,
    productFeaturingsReducer:ProductFeaturingConfig.reducer,
    categoryOfStoreReducer:CategoryOfStoreConfig.reducer
}
