import RegisterAsIndividual from "./RegisterAsIndividual/RegisterAsIndividual";
import RegisterSuccess from "react-platform-user/dist/Register/RegisterSuccess";
import RegisterWithStore from "./RegisterWithStore/RegisterWithStore"
import RegisterAs from "./RegisterAs"

export const CustomRegisterConfig = {
  auth: ["guest"],
  routes: [
     {
      component: RegisterAs,
      path: "/register",
    },
    {
      component: RegisterAsIndividual,
      path: "/register-individual",
    },
    {
      component: RegisterWithStore,
      path: "/register-enterprise",
    },
    {
      component: RegisterSuccess,
      path: '/register-success',
    },
  ],
  settings: {
    layout: {
      config: {
        footer: {
          display: false,
        },
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
      },
    },
  },
};
