import React from "react";
import {
  IntlMessages,
  injectIntl,
} from "react-platform-components/dist/Translations";
import { connect } from "react-redux";
import {Typography} from "@material-ui/core"

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { intl, user } = this.props;
    const userType = "user";
    return (
      <div className="p-4">
        <div className="p-4">
          <div className="pb-1 border-b border-gray-200 border-solid">
            <Typography variant="h1" className="m-0">
              <IntlMessages id="my_dashboard" defaultMessage="my_dashboard" />{" "}
              {user.first_name}
            </Typography>
            <Typography className="m-0">
              <IntlMessages
                id="Welcome to SDS"
                defaultMessage="Welcome to SDS"
              />
            </Typography>
          </div>
        </div>
        <div
          className="grid"
          style={{
            gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
          }}
        >
          {[1, 2, 3].map((id) => (
            <div
              className="m-4 rounded-2xl bg-gray-50 flex flex-col"
              style={{ backgroundColor: "#e4efed" }}
              key={id}
            >
              <div
                className="p-8 border-b border-gray-300 border-solid"
                style={{ justifyContent: "center", display: "flex" }}
              >
                <Typography variant="h3" className="m-0 text-xl ">
                  <IntlMessages
                    id={`Dashboard_title_${userType}_${id}`}
                    defaultMessage={`Dashboard_title_${userType}_${id}`}
                  />
                </Typography>
              </div>
              <div className="p-8 flex-grow">
                <Typography className="m-0 leading-loose">
                  <IntlMessages
                    id={`Dashboard_content_${userType}_${id}`}
                    defaultMessage={`Dashboard_content_${userType}_${id}`}
                  />
                </Typography>
              </div>
              <div className="p-8">
                <a
                  className="w-full"
                  href={intl.formatMessage({
                    id: `dashboard_button_link_${userType}_${id}`,
                    defaultMessage: "/dashboard",
                  })}
                >
                  <button className="py-4  rounded-full bg-white text-black hover:bg-gray-100 border-1 border-gray-500 cursor-pointer font-bold text-base w-full">
                    <Typography variant="h3">
                      <IntlMessages
                        id={`Dashboard_button_${userType}_${id}`}
                        defaultMessage={`Dashboard_button_${userType}_${id}`}
                        />
                    </Typography>
                  </button>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
export default connect((state) => {
  return {
    user: state.auth.user,
  };
})(injectIntl(Dashboard));
