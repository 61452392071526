import React from 'react'
import _ from 'lodash'
import { buildInjectToBaseRegisterContainer } from 'react-platform-user/dist/Register/Register'
import RegisterForm from 'react-platform-user/dist/Register/RegisterForm'
import { BUYER_ROLE_ID } from "../../../configs"

export default buildInjectToBaseRegisterContainer({
	form: RegisterForm,
	setRoleValueToFormData: ({ roles }) => {
		return { 'user_roles-0-role': _.find(roles, { id: BUYER_ROLE_ID })?.id }
	}
})
