import {CreateProductSalableWrapper} from "./CreateProductSalableWrapper"

export const CreateProductSalableConfig = {
  auth: ["user"],
  routes: [
    {
      component: CreateProductSalableWrapper,
      path: "/create-product/:id",
      exact: true,
    },
    {
      component: CreateProductSalableWrapper,
      path: "/create-product",
      exact: true,
    },

  ],
};
