import { getBackendConfig } from "react-platform-components/dist/Utils"

export const BASE_API_URL = getBackendConfig('REACT_BASE_API_URL', process.env.REACT_APP_BASE_API_URL)
export const BASE_URL = getBackendConfig('REACT_BASE_URL', process.env.REACT_APP_BASE_URL)

export const STATIC_PATH = process.env.PUBLIC_URL || ''
export const BASE_APP_URL = process.env.REACT_APP_BASE_APP_URL
export const LOGIN_PAGE = `${BASE_URL}${BASE_APP_URL}/login`


export const CATEGORIE_STORE_ENTREPRESE_UUID = 'c1145a48-0531-44f9-bbff-65006c289c36'
export const CATEGORIE_STORE_INDIVIDUAL_UUID = 'ef55e283-c275-4322-9265-68956b71721f'

export const STRIPE_KEY = getBackendConfig('STRIPE_API_KEY', process.env.REACT_APP_STRIPE_KEY)


export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
export const ENV = getBackendConfig("ENV", "dev");

export const CATEGORIE_STORE_ENTREPRISE_UUID = process.env.REACT_APP_CATEGORIE_STORE_ENTREPRISE_UUID
export const CATEGORIE_STORE_ASSOCIATION_UUID = process.env.REACT_APP_CATEGORIE_STORE_ASSOCIATION_UUID
export const ENTREPRISE_ROLE_ID = process.env.REACT_APP_ENTREPRISE_ROLE_ID
export const ASSOCIATION_ROLE_ID = process.env.REACT_APP_ASSOCIATION_ROLE_ID
export const BUYER_ROLE_ID = process.env.REACT_APP_BUYER_ROLE_ID