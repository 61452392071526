import React from 'react'
import { CreateProductSalableForm } from 'react-platform-productsalable/dist/CreateProductSalable/forms/CreateProductSalableForm'
import { InformationsStepFields } from 'react-platform-productsalable/dist/CreateProductSalable/forms/steps/InformationStep'
import {AddressStepFields} from "./steps/AddressStep"
import { getFormInitialValuesKeys as defaultGetFormInitialValuesKeys } from 'react-platform-productsalable/dist/CreateProductSalable/forms/CreateProductSalableForm'
import {SchedulerStep,days} from "./steps/SchedulerStep.jsx"
import _ from 'lodash'

export const getFormInitialValuesKeys = () => {
    const initialValuesKeys = [
        ...defaultGetFormInitialValuesKeys(),
        "address",
    ]
    return initialValuesKeys
}

export const CustomCreateProductSalableForm = () => {
    return (
        <CreateProductSalableForm
        getStepsContent={(olderSteps)=>[
        {label:"Informations step",component:InformationsStepFields},
        {label:"Schedules step",component:SchedulerStep},
        {label:"Address step",component:AddressStepFields}
        ]}
        getFormInitialValuesKeys={getFormInitialValuesKeys}
        getCustomInitialValues={(oldValues, product) =>  {
            var newValue = {...oldValues}
            if(!_.isEmpty(product.schedules)){
              const keys=Object.keys(_.groupBy(product.schedules,"day"))
              keys.map((key,index)=>{
            newValue[`${key}schedules`]=product.schedules.filter((item)=>item.day===key)
            })
            }
            return newValue
        }}
        endpoint="products"
        onSave={{
                redirectAfterActionTo: () => {return '/products'},
                setAdditionalValuesToFormData: (formData, data, product, isUpdating) => {
        if(!_.isEmpty(data.schedules)){
            data.schedules.map((item,index)=>(
              formData.append(`schedules-${index}-id`, item.id),
              formData.append(`schedules-${index}-inline_delete`, "true")
            ))
          }
        
                let scheduledDays=[]
                  days.map((day)=>{
                      if(!!data[`${day}schedules`]){
                        scheduledDays.push(data[`${day}schedules`])
                      }
                    })
                scheduledDays=scheduledDays.flat()
          const dataSchedulesLength=data?.schedules?.length||0
        scheduledDays.map((schedule,index)=>( 
          formData.append(`schedules-${index+dataSchedulesLength}-day`,schedule.day),
          formData.append(`schedules-${index+dataSchedulesLength}-start_at`,schedule.start_at),
          formData.append(`schedules-${index+dataSchedulesLength}-end_at`,schedule.end_at),
          formData.append(`schedules-${index+dataSchedulesLength}-min_age`,schedule.min_age),
          formData.append(`schedules-${index+dataSchedulesLength}-max_age`,schedule.max_age)
        ))
                }
            }}
        />
    )
}
