import React, { useEffect } from 'react';
import { FieldArray, useFormikContext,ErrorMessage, Field } from 'formik';
import {TextField} from "react-platform-components/dist/form-fields/index"
import { required, validators, isPositive, number } from 'react-platform-components/dist/form-validators';
import { Grid,Button,Typography, IconButton,Icon,makeStyles} from '@material-ui/core';
import { IntlMessages } from 'react-platform-components/dist/Translations';
import _ from 'lodash';

export const days=["monday","tuesday","wednesday","thursday","friday","saturday","sunday"]

const useStyle = makeStyles((theme) => ({
  display:{
    display:"block"
  },
  wrapper:{
    width:"100%",margin:"10px 0"
  },
  marginAdd:{
    marginTop:"15px"
  },
  errorMessage:{
    color:"#f44336",marginBottom:"20px"
  },
  fieldsWrapper:{
    margin:"10px 0"
  }
}))

export const SchedulerStep = () => {
  const classes=useStyle()
  const {values,setFieldValue,errors}=useFormikContext()

  //check if in the values object we have a property with a namming like mondayschedules...etc so we know then that we have set a value so at least one schedule
  function hasDayProperty(){
    let hasProperty=false
    days.map((day,index)=>{
      if(Object(values).hasOwnProperty(`${day}schedules`)){
         hasProperty=true
      }
    })
    return hasProperty
  }

  const canSubmit=hasDayProperty()

 useEffect(()=>{
  if(canSubmit) setFieldValue("validation_field","full")
  },[canSubmit]) 

  const isMax = (age) => (value) =>  {
	if (parseInt(age) < parseInt(value)) {
		return "la valeur max âge doit être supérieur à la valeur min âge"
	} 
}

const isMin = (age) => (value) =>  {
	if (parseInt(age) > parseInt(value)) {
		return "la valeur min âge doit être inférieur à la valeur age âge"
	} 
}

return   <div className={classes.wrapper}>
    {/* hide the useless field */}
    <span className={classes.display}>
      <Field name="validation_field" label="validation field" type="text" required value="" validate={required()} validateOnChange={false} validateOnBlur={false} ></Field>
    </span>
    <div className={classes.marginAdd}>
      {!_.isEmpty(errors.validation_field)&&
      <span className={classes.errorMessage}>{errors.validation_field.props.id}</span>
    }
          {days.map((day,dayIndex)=>{ 
       return <FieldArray  name={day+"schedules"}>
        {({remove, push}) => { 
            return<>
              <Grid container xs={12} md={12}>
                <Grid item xs={6} md={6}>
                  <Typography>
                    <IntlMessages id={day} defaultMessage={day}/>
                  </Typography>
                </Grid>

                <Grid item xs={6} md={6}>
                  <Button
                    color="primary"
                    onClick={()=>
                  {
                      push(
                        {
                          day:day,
                          dayIndex:dayIndex,
                          start_at:"",
                          end_at:"",
                          min_age:"",
                          max_age:"", 
                        },
                      )
                    }

                  }
                  >
                      <IntlMessages id="Add Schedule" defaultMessage="Add Schedule"/>
                  </Button>
                </Grid>
              </Grid>
              {values[`${day}schedules`]?.length > 0 &&
                values[`${day}schedules`].map((schedule, index) => {
                    let name=`${day}schedules`
                  return <Grid container xs={12} md={12} spacing={1}  key={index} className={classes.fieldsWrapper}>
                    <Grid item xs={2} >
                      <TextField
                        validate={required()}
                        required
                        label="Start at"
                        name={`${name}[${index}].start_at`}
                        type="time"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300
                        }}
                        />

                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        validate={required()}
                        required
                        label="End at *"
                        name={`${name}[${index}].end_at`}
                        type="time"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300
                        }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        validate={validators([required(), number(), isPositive() , isMax(values[name][index]?.max_age)])}
                        required
                        label="Min age"
                        name={`${name}[${index}].min_age`}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300
                        }}
                        />

                    </Grid>
                    <Grid item xs={2}>
                      <TextField
                        validate={validators([required(), number(), isPositive() , isMin(values[name][index]?.min_age)])}
                        required
                        label="Max age"
                        name={`${name}[${index}].max_age`}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300
                        }}
                        />

                    </Grid>
                    <Grid item >
                      <IconButton
                        type="button"
                        className="secondary"
                        onClick={() => {
                          remove(index)
                          }}
                      >
                        <Icon>close</Icon>
                      </IconButton>
                    </Grid>
                  </Grid>
                }
                )}

              </>
        }}
          </FieldArray>
          })}
        </div>
  </div>
};


