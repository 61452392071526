import { createGenerateClassName, jssPreset, StylesProvider } from '@material-ui/styles';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core"
import { create } from 'jss';
import jssExtend from 'jss-extend';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Router } from 'react-router-dom';
import Authorization from 'react-platform-user/dist/Authorization/Authorization';
import { withTranslationProvider } from "react-platform-components/dist/Translations/hoc";
import { BASE_APP_URL, ENV, SENTRY_DSN } from './configs';
import { App } from './containers';
import history from './history';
import { runSaga, store } from './redux';
import { routes } from './init';
import * as serviceWorker from './serviceWorker';
import './styles/helpers.css';
import './styles/styles.css';
import { STRIPE_KEY } from './configs';
import { StripeProvider } from 'react-stripe-elements';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { PreloadApplication } from "react-platform-components/dist/PreloadApplication/PreloadApplication";


if (ENV != "dev" && SENTRY_DSN) {
    Sentry.init({
        dsn: SENTRY_DSN,
        environment: ENV,
        autoSessionTracking: true,
        integrations: [new Integrations.BrowserTracing()],
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    });
}


const theme = createMuiTheme({
    palette: {
        type:"light",
        primary: {
            main: "#0277bd",
            contrastText: "#ffff"
        },
        secondary: {
            main: '#004c8c',
            contrastText: "#ffff"
        },
        third: {
            main: "#90caf9",
            secondary: "#0277bd",
            dark: "#E57373",
            contrastText: "#000000",
            noContrastText: "#FFFFFF",
        },
    background: {
      default: '#f5f5fa',
    },
    },
    typography: {
        fontWeightLight: 300,
        fontWeight:400,
       h5:{
        fontWeight:600,
        fontSize:"1.5rem",
        lineHeight:1.334,
        color:"#000"
      },
      h3:{
      fontSize:"1.25rem",
      fontWeight:600,
      lineHeight:"23px",
      letterSpacing:"1px",
    },
    h1:{
      fontSize:"2em"
    }
    },
})

const jss = create({
    ...jssPreset(),
    insertionPoint: 'jss-insertion-point',
    plugins: [...jssPreset().plugins, jssExtend()]
});

jss.options.createGenerateClassName = createGenerateClassName;

const generateClassName = createGenerateClassName();

runSaga();


const RootComponent = withTranslationProvider(() => {
    // we use React router here becuse we need it in the root component
    return (
      <Router history={history}>
        <BrowserRouter basename={BASE_APP_URL}>
            <PreloadApplication>
                <Authorization routes={routes}>
                    <App />
                </Authorization>
            </PreloadApplication>
        </BrowserRouter>
      </Router>
    )
})


ReactDOM.render(
    <StylesProvider injectFirst jss={jss} generateClassName={generateClassName}>
            <StripeProvider apiKey={STRIPE_KEY}>
                <MuiThemeProvider theme={theme}>
                    <Provider store={store}>
                        <RootComponent />
                    </Provider>
                </MuiThemeProvider>
            </StripeProvider>
        </StylesProvider>,
        document.getElementById('root')
    
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
